amplify-authenticator {
    background: tomato;
    padding: 5px;
}

container {
    flex: 1;
    flexDirection: 'column';
    alignItems: 'center';
    justifyContent: 'space-around';
    paddingTop: 20;
    width: 100%;
    backgroundColor: '#FFF';
}
section {
    flex: 1;
    width: 100%;
    justifyContent: 'space-between';
    paddingHorizontal: 20;
}
