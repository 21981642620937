.trigger {
    left: 10px;
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout{
    padding: 0px;
}

.site-header {
    background: #fff;
    padding: 0px;
}

.site-layout-background {
    background: #fff;
    padding: 0px;
}

.site-content {
    background: #fff;
    padding: 0;
    margin: 24px 16px 0px;
    padding: 24px;
    minHeight: 280;
    overflow: scroll;
}

.TemplateScreen {
    height: 100vh;
}
