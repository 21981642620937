.spinner {
    margin-left: 50%;
    margin-top: 25%;
}

.row-bottom-margin-20px {
    margin-bottom: 20px;
}

.page-divider-col {
    text-align: center;
}

.page-divider {
    height: 100%;
}
